import { sendEventToAnalytics } from '@lib/helpers/client'

export function customerInsightCTAClickedEvent(): void {
  const eventName = 'web_customer_insight_cta_click'
  const payload = {}
  sendEventToAnalytics(eventName, payload)
}

export function customerInsightDismissedEvent(): void {
  const eventName = 'web_customer_insight_dismissed'
  const payload = {}
  sendEventToAnalytics(eventName, payload)
}
