import { sendEventToAnalytics } from '@lib/helpers/client'

type LoginEventPayload = {
  source: string
  event_label?: string
  method?: string
  jobSeekerId?: string
}

export function loginEmailSetEvent(provider: string): void {
  const eventName = 'web_email_set'
  const payload = { provider }
  sendEventToAnalytics(eventName, payload)
}

export function loginOTPEnteredEvent(source: string): void {
  const eventName = 'web_otp_entered'
  const payload: LoginEventPayload = { source }
  sendEventToAnalytics(eventName, payload)
}

export function loginOTPSentAgainEvent(source: string): void {
  const eventName = 'web_otp_sent_again'
  const payload: LoginEventPayload = { source }
  sendEventToAnalytics(eventName, payload)
}

export function loginOTPSentEvent(source: string): void {
  const eventName = 'web_otp_sent'
  const payload: LoginEventPayload = { source }
  sendEventToAnalytics(eventName, payload)
}

export function loginResetPasswordEvent(source: string): void {
  const eventName = 'web_reset_password'
  const payload: LoginEventPayload = { source }
  sendEventToAnalytics(eventName, payload)
}

export function loginSignInEvent(jobSeekerId: string, source: string, method: string): void {
  const eventName = 'web_login'
  const payload = {
    event_label: jobSeekerId,
    method,
    jobSeekerId,
    source,
  }
  sendEventToAnalytics(eventName, payload)
}

export function loginSignOutEvent(): void {
  const eventName = 'web_logout'
  const payload = {}
  sendEventToAnalytics(eventName, payload)
}

export function loginSignUpEvent(jobSeekerId: string, source: string, method: string): void {
  const eventName = 'web_sign_up'
  const payload: LoginEventPayload = {
    event_label: jobSeekerId,
    method,
    jobSeekerId,
    source,
  }
  sendEventToAnalytics(eventName, payload)
}
