import { JobItem, JobPageItem, JobSearch } from '@types'

import { parseCookies } from 'nookies'

import { booleanToHumanString, sendEventToAnalytics } from '@lib/helpers/client'

type JobEventPayload = {
  event_label: string
  hasBenefits: string
  jobCompanyName: string
  jobFeed: string
  jobGroupCategory: string
  jobId: string
  jobSource: string
  jobTitle: string
  non_interaction: boolean
  searchLocationId: string
  searchLocationName: string
  searchLocationType: string
  searchMacroRole: string
  searchMicroRole: string
  search_strategy: string
  position?: string
  softLogin?: string
}

function createJobEventPayload(
  job: JobItem | undefined,
  search: JobSearch,
  serpPositionClicked?: string,
  locale?: string,
): JobEventPayload {
  return {
    event_label: job?.id || '',
    hasBenefits: booleanToHumanString(Number(job?.benefits?.length) > 0),
    jobCompanyName: job?.companyName || '',
    jobFeed: job?.feed || '',
    jobGroupCategory: job?.groupCategory || '',
    jobId: job?.id || '',
    jobSource: job?.source || '',
    jobTitle: job?.title || '',
    non_interaction: true,
    searchLocationId: search.location.id || '',
    searchLocationName: search.location.name || '',
    searchLocationType: search.location.type || '',
    searchMacroRole: search.job.macro || '',
    searchMicroRole: search.job.micro || '',
    search_strategy: search.sort || '',
    ...(serpPositionClicked && { position: serpPositionClicked }),
    ...(locale && { softLogin: booleanToHumanString(!!parseCookies()[`${locale}-soft-login`]) }),
  }
}

export function jobApplyButtonClickedEvent(job: JobItem, search: JobSearch): void {
  const eventName = 'web_apply_button_clicked'
  const payload: JobEventPayload = createJobEventPayload(job, search)
  sendEventToAnalytics(eventName, payload)
}

export function jobInactiveJobEvent(): void {
  const eventName = 'web_user_landed_on_inactive_job'
  const payload = {}
  sendEventToAnalytics(eventName, payload)
}

export function jobPageViewEvent(
  job: JobPageItem | undefined,
  search: JobSearch,
  serpPositionClicked: string,
  locale: string,
): void {
  const eventName = 'web_job_ad_opened'
  const payload: JobEventPayload = createJobEventPayload(job as unknown as JobItem, search, serpPositionClicked, locale)
  sendEventToAnalytics(eventName, payload)
}

export function jobUnknownJobEvent(): void {
  const eventName = 'web_user_landed_on_unknown_job'
  const payload = {}
  sendEventToAnalytics(eventName, payload)
}
