import { sendEventToAnalytics } from '@lib/helpers/client'

export default function smsAppLinkSentEvent(phoneNumber: string, platform: string): void {
  const eventName = 'web_sms_sent'
  const payload = {
    event_label: phoneNumber,
    jobSeekerPhone: phoneNumber,
    platform,
  }
  sendEventToAnalytics(eventName, payload)
}
