import { JobItem } from '@types'

import { JobCardItem, TinyJobItem } from 'src/types/job-item'

import getSalaryTypeLabel from './get-salary-type-label'

export default function getSalaryFromJob(job: TinyJobItem | JobCardItem | JobItem, currency: string): string {
  if (job?.externalJobDetail?.salaryDescription) {
    return job.externalJobDetail.salaryDescription
  }

  if (job?.salaryDescription) {
    return job.salaryDescription
  }

  if (job?.minimumSalary) {
    return `${job?.minimumSalary}${currency} ${getSalaryTypeLabel(job?.salaryType)}`
  }

  return 'Competitive'
}
