import { booleanToHumanString, sendEventToAnalytics } from '@lib/helpers/client'

export function smartApplyApplicationsPageViewEvent(recommendations: number, isSmartApplyActive?: boolean): void {
  const eventName = 'web_smart_apply_applications'
  const payload = {
    isSmartApplyActive: booleanToHumanString(isSmartApplyActive),
    numberRecommendations: String(recommendations),
  }
  sendEventToAnalytics(eventName, payload)
}

export function smartApplyPageViewEvent(): void {
  const eventName = 'web_smart_apply_home'
  const payload = {}
  sendEventToAnalytics(eventName, payload)
}

export function smartApplyToggleEvent(value: boolean): void {
  const eventName = 'web_smart_apply_toggle'
  const payload = { value: booleanToHumanString(value) }
  sendEventToAnalytics(eventName, payload)
}
