import { Country } from '@types'

import { sendEventToAnalytics } from '@lib/helpers/client'

export function localeSelectorChangeLocationEvent(currentCountry: Country, userCountry?: Country | false): void {
  const eventName = 'web_location_popup_cta_click'
  const payload = {
    userCountry: (userCountry as string) ?? null,
    currentCountry: currentCountry as string,
  }
  sendEventToAnalytics(eventName, payload)
}

export function localeSelectorCloseEvent(currentCountry: Country, userCountry?: Country | false): void {
  const eventName = 'web_close_location_popup'
  const payload = {
    userCountry: userCountry as string,
    currentCountry: currentCountry as string,
  }
  sendEventToAnalytics(eventName, payload)
}

export function localeSelectorShowEvent(currentCountry: Country, userCountry?: Country | false): void {
  const eventName = 'web_location_popup_display'
  const payload = {
    userCountry: userCountry as string,
    currentCountry: currentCountry as string,
  }
  sendEventToAnalytics(eventName, payload)
}
