import { sendEventToAnalytics } from '@lib/helpers/client'

export function miscCVUploadVideoStartEvent(): void {
  const eventName = 'web_video_start:cv_upload'
  const payload = {}
  sendEventToAnalytics(eventName, payload)
}

export function miscFailedToSetUserSourceEvent(referrer: string): void {
  const eventName = 'web_failed_to_set_user_source'
  const payload = { referrer }
  sendEventToAnalytics(eventName, payload)
}

export function miscPageViewEvent(name: string): void {
  const eventName = 'web_screen_open'
  const payload = { web_screen_name: name }
  sendEventToAnalytics(eventName, payload)
}

export function miscRedirectToSearchPageEvent(): void {
  const eventName = 'web_from_login_to_search'
  const payload = {}
  sendEventToAnalytics(eventName, payload)
}

export function miscSocialMediaLinkClickEvent(social: string): void {
  const eventName = 'Social Media Link Click'
  const payload = { social }
  sendEventToAnalytics(eventName, payload)
}

export function miscVideoPageViewEvent(): void {
  const eventName = 'web_video_land'
  const payload = {}
  sendEventToAnalytics(eventName, payload)
}
