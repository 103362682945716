import { JobItem, JobPageItem, JobSearch, TinyJobItem } from '@types'

import { getSalaryFromJob, sendEventToAnalytics } from '@lib/helpers/client'

export function chatAGACongratulationsReceivedEvent(): void {
  const eventName = 'web_AGA_congratulation_message_seen'
  const payload = {}
  sendEventToAnalytics(eventName, payload)
}

export function chatAcceptedTermsConditionsEvent(): void {
  const eventName = 'web_accepted_TandCs'
  const payload = {}
  sendEventToAnalytics(eventName, payload)
}

export function chatApplicationCompletedEvent(
  eventData: Record<string, string | boolean>,
  job: JobPageItem | undefined,
  status: boolean,
  search: JobSearch,
  serpPositionClicked: number | undefined,
  jobIds: string[],
): void {
  const eventName = 'web_application_completed'
  const payload = {
    ...eventData,
    event_label: job?.id || '',
    jobId: job?.id || '',
    status: status ? 'SUCCESS' : 'FAILURE',
    microRole: job?.microRole ?? search.job.micro ?? '',
    macroRole: search.job.macro ?? '',
    location: job?.address?.city ?? search.location.name ?? '',
    position: String(serpPositionClicked),
    search_score: String(job?.classification?.score || ''),
    search_strategy: search.sort,
    'Number of applications (Mass Apply)': String(jobIds.length),
    suggestedJobsApplications: String(Math.max(0, jobIds.length - 1)),
  }
  sendEventToAnalytics(eventName, payload)
}

export function chatCVCreationCompletedEvent(type: string, jobId?: string): void {
  const eventName = 'web_cv_completed'
  const payload = {
    event_label: type,
    cvType: type,
    jobId: jobId || '',
  }
  sendEventToAnalytics(eventName, payload)
}

export function chatCVCreationStartedEvent(): void {
  const eventName = 'web_cv_creation'
  const payload = {}
  sendEventToAnalytics(eventName, payload)
}

export function chatCVUploadSuccessEvent(): void {
  const eventName = 'web_correct_cv_upload'
  const payload = {}
  sendEventToAnalytics(eventName, payload)
}

export function chatContactInformationSubmittedEvent(): void {
  const eventName = 'web_contact_information_complete'
  const payload = {}
  sendEventToAnalytics(eventName, payload)
}

export function chatEducationInformationSubmittedEvent(): void {
  const eventName = 'web_last_education_info_complete'
  const payload = {}
  sendEventToAnalytics(eventName, payload)
}

export function chatEmailSetEvent(email: string, status: string): void {
  const eventName = 'web_aga_email_set'
  const payload = { email, status }
  sendEventToAnalytics(eventName, payload)
}

export function chatLegacyCVCreationStartedEvent(jobId?: string): void {
  const eventName = 'legacy_web_cv_started'
  const payload = {
    event_label: jobId ?? '',
    jobId: jobId ?? '',
  }
  sendEventToAnalytics(eventName, payload)
}

export function chatReplacedCVEvent(): void {
  const eventName = 'replace_cv_upload'
  const payload = {}
  sendEventToAnalytics(eventName, payload)
}

export function chatShowCustomQuestionsEvent(): void {
  const eventName = 'web_custom_questions_presented'
  const payload = {}
  sendEventToAnalytics(eventName, payload)
}

export function chatSimilarJobMessageToggleJobEvent(
  toggleValue: boolean,
  suggestion: TinyJobItem,
  currency: string,
  job?: JobPageItem,
): void {
  const eventName = 'Similar Job Message - Toggle suggestion'
  const payload = {
    status: toggleValue ? 'Added' : 'Removed',
    'Initial Job Id': job?.id ?? '',
    'Initial Job title': job?.title ?? '',
    'Initial Job location': job?.address?.city ?? '',
    'Initial Job salary': getSalaryFromJob(job as unknown as JobItem, currency),
    'Suggestion Job Id': suggestion.id,
    'Suggestion Job title': suggestion.title,
    'Suggestion Job location': suggestion.address?.city ?? '',
    'Suggestion Job salary': getSalaryFromJob(suggestion, currency),
  }
  sendEventToAnalytics(eventName, payload)
}

export function chatUploadCVEvent(): void {
  const eventName = 'web_upload_cv'
  const payload = {}
  sendEventToAnalytics(eventName, payload)
}

export function chatUploadPhotosEvent(): void {
  const eventName = 'web_upload_photos'
  const payload = {}
  sendEventToAnalytics(eventName, payload)
}

export function chatUploadedWrongCVExtensionEvent(file: File): void {
  const eventName = 'web_wrong_cv_extension'
  const payload = {
    fileName: file.name,
    type: file.type,
  }
  sendEventToAnalytics(eventName, payload)
}

export function chatWorkInformationSubmittedEvent(): void {
  const eventName = 'web_last_work_experience_info_complete'
  const payload = {}
  sendEventToAnalytics(eventName, payload)
}
