import nookies from 'nookies'

export const COOKIE_CONSENT_NAME = 'cookies-are-accepted'

const getCookieConsentValue = (): boolean => {
  const cookiesAccepted = nookies.get()[COOKIE_CONSENT_NAME]

  return cookiesAccepted === 'true'
}

export default getCookieConsentValue
