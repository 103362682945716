import { JobSearch } from '@types'

import { sendEventToAnalytics } from '@lib/helpers/client'

export function serpFiltersOpenedEvent(): void {
  const eventName = 'web_filter_open'
  const payload = {}
  sendEventToAnalytics(eventName, payload)
}

export function serpFiltersUpdatedEvent(search: JobSearch): void {
  const eventName = 'web_filter_changed'
  const payload = {
    filter_employment_full_time: search.fullTime,
    filter_employment_part_time: search.partTime,
    filter_experience: search.noExperience,
    filter_salary: String(search.salary.min),
    filter_distance: `${search.maxRadius} mi.`,
    filter_remote: String(search.remote),
    filter_created_after: search.createdAfter || '',
  }
  sendEventToAnalytics(eventName, payload)
}

export function serpPageViewEvent(search: JobSearch, totalElements?: number): void {
  const eventName = 'web_find_jobs_open'
  const payload = {
    searchMicroRole: search.job.micro,
    searchMacroRole: search.job.macro || '',
    searchLocationName: search.location.name,
    searchLocationType: search.location.type,
    searchLocationId: search.location.id,
    non_interaction: true,
    liquidity: String(totalElements || ''),
    web_sort_changed: search.sort,
  }
  sendEventToAnalytics(eventName, payload)
}
