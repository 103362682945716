import { sendEventToAnalytics } from '@lib/helpers/client'

export function reminderOpenedEvent(): void {
  const eventName = 'web_job_reminder_modal_open'
  const payload = {}
  sendEventToAnalytics(eventName, payload)
}

export function reminderSetEvent(time: string): void {
  const eventName = 'web_job_reminder_set'
  const payload = { time }
  sendEventToAnalytics(eventName, payload)
}
