export default function getSalaryTypeLabel(salaryType?: string): string | undefined {
  switch (salaryType) {
    case 'PER_HOUR':
      return 'Per Hour'
    case 'PER_ANNUM':
      return 'Per Annum'
    case 'PER_DAY':
      return 'Per Day'
    case 'PER_MONTH':
      return 'Per Month'
    case 'PER_WEEK':
      return 'Per Week'
    default:
      return salaryType
  }
}
