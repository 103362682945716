'use client'

import * as gtag from '@lib/gtag'

import getCookieConsentValue from './get-cookie-consent-value'

export default async function sendEventToAnalytics(
  event: string,
  params: Record<string, string | boolean | string[]>,
): Promise<void> {
  const { mixpanel } = await import('@lib/mixpanel')
  gtag.event(event, params)
  mixpanel.track(event, { ...params, areCookiesAccepted: getCookieConsentValue() })
}
