import { JobSearch } from '@types'

import { booleanToHumanString, sendEventToAnalytics } from '@lib/helpers/client'

type SearchEventPayload = {
  location: string
  role: string
  fullTime: string
  partTime: string
  noExperience: string
  minSalary: string
  screen_name: string
  remote: string
  maxRadius: string
  createdAfter: string
}

export default function searchSubmittedEvent(search: JobSearch, screen: string): void {
  const eventName = 'web_search_button_clicked'
  const payload: SearchEventPayload = {
    location: search.location.displayName,
    role: search.job.micro,
    fullTime: booleanToHumanString(search.fullTime),
    partTime: booleanToHumanString(search.partTime),
    noExperience: booleanToHumanString(search.noExperience),
    remote: booleanToHumanString(search.remote),
    minSalary: search.salary.min?.toString() || '',
    maxRadius: search.maxRadius.toString(),
    createdAfter: search.createdAfter || '',
    screen_name: screen,
  }

  sendEventToAnalytics(eventName, payload)
}
