import getCookieConsentValue from './helpers/client/get-cookie-consent-value'

export function event(action: string, params: Record<string, unknown>): void {
  if (typeof window.gtag === 'function') {
    window?.gtag('event', action, params)
  }
}

export function updateConfig(): void {
  if (typeof window !== 'undefined' && typeof window?.gtag === 'function') {
    const cookieConsent = getCookieConsentValue()

    if (!cookieConsent) {
      window.gtag('consent', 'default', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
      })
    } else {
      window?.gtag('consent', 'default', {
        ad_storage: 'granted',
        analytics_storage: 'granted',
      })
    }
  }
}
