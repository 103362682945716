import { JobSearch } from '@types'

import { sendEventToAnalytics } from '@lib/helpers/client'

type FAQEventPayload = {
  microRole: string
  macroRole: string
  location: string
  jobId: string
}

function createFAQEventPayload(search: JobSearch, jobId?: string): FAQEventPayload {
  return {
    microRole: search.job.micro,
    macroRole: search.job.macro ?? '',
    location: search.location.name ?? '',
    jobId: jobId ?? '',
  }
}

export function faqAnotherIssueClickedEvent(search: JobSearch, jobId?: string): void {
  const eventName = 'web_faq_another_issue'
  const payload: FAQEventPayload = createFAQEventPayload(search, jobId)
  sendEventToAnalytics(eventName, payload)
}

export function faqOpenByIdleEvent(search: JobSearch, jobId?: string): void {
  const eventName = 'web_faq_open:idle'
  const payload: FAQEventPayload = createFAQEventPayload(search, jobId)
  sendEventToAnalytics(eventName, payload)
}

export function faqOpenEvent(search: JobSearch, jobId?: string): void {
  const eventName = 'web_faq_open:click'
  const payload: FAQEventPayload = createFAQEventPayload(search, jobId)
  sendEventToAnalytics(eventName, payload)
}

export function faqQuestionOpenedEvent(question: string): void {
  const eventName = 'web_open_faq_question'
  const payload = { question }
  sendEventToAnalytics(eventName, payload)
}
